import i18n from '@/plugins/i18n'
import moment from 'moment'

export function required(value, message) {
  return !!value || i18n.t(message || 'inputRules.required')
}

export function pwdMinLength(value) {
  return (value && value.length >= 8) || 'Min 8 characters'
}

export function pwdMatch(val1) {
  return val2 => {
    return val1 == val2 || 'Should match password'
  }
}

export function minVal(minVal, message) {
  return value => {
    return !value || value >= minVal || i18n.t(message || 'inputRules.minVal', { minVal })
  }
}

export function maxVal(maxVal, message) {
  return value => {
    return !value || value < maxVal || i18n.t(message || 'inputRules.maxVal', { maxVal })
  }
}

export function email(value, message) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/
  return pattern.test(value) || i18n.t(message || 'inputRules.emailInvalid')
}

export function date(format = 'YYYY-MM-DD', message) {
  return value => {
    return !value || moment(value, format, true).isValid() || message || format
  }
}

export function time(value) {
  const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  return !value || pattern.test(value) || i18n.t('inputRules.timeInvalid')
}
