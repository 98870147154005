<template>
  <auth-container :logo="false">
    <div class="my-5">
      <v-layout column justify-center align-end>
        <h2 class="display-2">{{ $t('auth.forgotHeader') }}</h2>
        <p class="pwd-reset__subhead body-1 pt-2">
          {{ $t('auth.remember') }}
          <router-link :to="{ name: 'auth.login' }">{{ $t('auth.signIn') }}</router-link>
        </p>
      </v-layout>
    </div>
    <v-layout column>
      <v-form @submit="requestPwdReset">
        <p>{{ $t('auth.forgotExpl') }}</p>
        <v-text-field
          v-model="email"
          prepend-icon="tl-email"
          type="email"
          :label="$t('profile.info.email')"
          :rules="rules"
          required
        />
        <v-flex align-self-end>
          <v-btn type="submit" color="primary" :loading="processing">{{ $t('auth.resetPwd') }}</v-btn>
        </v-flex>
      </v-form>
    </v-layout>
  </auth-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import { beforeRouteEnter as loginGuard } from './auth.guard'
import { email } from '@/services/input-rules'
import authContainer from './tl-auth-container.vue'

export default {
  mixins: [toolbarMixin],
  components: {
    authContainer,
  },
  head() {
    return {
      title: this.$t('auth.resetPwdTitle'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('auth.resetPwdDescription') }],
    }
  },
  data: () => ({
    email: '',
    processing: false,
    rules: [email],
  }),
  computed: {
    tlToolbarTitle() {
      return this.$t('auth.resetPwd')
    },
  },
  beforeRouteEnter: loginGuard,
  methods: {
    requestPwdReset(e) {
      e.preventDefault()
      this.processing = true
      this.$store
        .dispatch('auth/requestPwdReset', { email: this.email })
        .then(() => this.$router.push({ name: 'auth.login' }))
        .catch(() => {})
        .finally(() => (this.processing = false))
    },
  },
}
</script>

<style lang="sass" scoped>
.pwd-reset
  &__subhead
    color: var(--v-grey-base)
</style>
