<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-layout column fill-height justify-center align-center>
          <v-flex>
            <h1 class="headline">Logging out...</h1>
          </v-flex>
          <v-progress-circular indeterminate color="primary" :size="100" :width="8" class="ma-5" />
        </v-layout>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'

export default {
  mixins: [toolbarMixin],
  computed: {
    tlToolbarTitle() {
      return this.$t('sidenav.signOut')
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (store.getters['kiosk/isKioskLocked']) {
      let correctPwd = await store.dispatch('kiosk/authKioskMode', { gym: store.state.gym })
      if (!correctPwd) return next(false)
    }
    next()
  },
  async mounted() {
    await this.$store.dispatch('auth/logout')
    this.$router.push({ name: 'auth.login' })
  },
}
</script>
