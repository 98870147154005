<template>
  <auth-container>
    <v-card dark hover class="login">
      <div class="login__header text-center">
        <v-scroll-x-transition hide-on-leave>
          <v-layout v-if="isSignUp" column justify-center align-center class="login__header-text mx-4">
            <h2 class="display-2">{{ $t('auth.createAccount') }}</h2>
            <p class="login__header-sub body-1" :class="{ 'login__header-sub--center': $vuetify.breakpoint.xsOnly }">
              {{ $t('auth.haveAccount') }}
              <a @click="togglePageType">{{ $t('auth.signIn') }}</a>
            </p>
          </v-layout>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <v-icon v-if="!isSignUp" light class="pa-3 login__header-icon">tl-account-circle</v-icon>
        </v-scroll-x-transition>
      </div>
      <v-layout column class="tl-layout-row-md-and-up pa-3 login__form-container">
        <v-flex xs12 md6 class="pa-3">
          <v-form ref="form" v-model="valid" @submit="submit">
            <v-layout column>
              <v-text-field
                v-model="email"
                :rules="rulesEmail"
                prepend-icon="tl-email"
                type="email"
                :label="$t('profile.info.email')"
                required
              />
              <v-text-field
                v-model="pwd"
                :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
                :rules="rulesPwd"
                :type="showPwd ? 'text' : 'password'"
                :label="$t('auth.password')"
                :counter="isSignUp"
                required
                prepend-icon="tl-lock"
                @click:append="showPwd = !showPwd"
              />
              <v-expand-transition>
                <div v-show="isSignUp">
                  <v-text-field
                    v-model="pwdConfirm"
                    :append-icon="showPwdConfirm ? 'tl-visibility-off' : 'tl-visibility'"
                    :rules="rulesPwdConfirm"
                    :type="showPwdConfirm ? 'text' : 'password'"
                    :label="$t('auth.passwordConfirm')"
                    required
                    prepend-icon="tl-lock"
                    @click:append="showPwdConfirm = !showPwdConfirm"
                  />
                  <v-checkbox v-model="newsletters" :label="$t('auth.newsletters')" color="primary" />
                </div>
              </v-expand-transition>
              <v-btn
                type="submit"
                :loading="authenticating"
                :disabled="submitDisabled"
                raised
                color="primary"
                class="my-2"
              >
                {{ submitBtnText }}
              </v-btn>
              <v-layout row wrap justify-space-around align-content-center>
                <v-btn text class="my-2" color="grey" @click="togglePageType">
                  <v-icon v-if="isSignUp" left>tl-input</v-icon>
                  <v-icon v-else left>tl-check-circle</v-icon>
                  {{ toggleTypeBtnText }}
                </v-btn>
                <v-btn text color="grey" class="my-2" :to="{ name: 'gyms' }">
                  <v-icon left>tl-account-circle</v-icon>
                  {{ $t('auth.guestAccount') }}
                </v-btn>
                <v-btn text color="grey" class="my-2" :to="{ name: 'auth.resetPwdReq' }">
                  <v-icon left>tl-lock</v-icon>
                  {{ $t('auth.forgotBtn') }}
                </v-btn>
              </v-layout>
            </v-layout>
          </v-form>
        </v-flex>

        <v-flex class="pa-3">
          <v-layout row tl-layout-column-md-and-up fill-height justify-center align-center>
            <v-flex class="login__type-divider-line" />
            <v-flex shrink class="pa-2">OR</v-flex>
            <v-flex class="login__type-divider-line" />
          </v-layout>
        </v-flex>

        <v-flex xs12 md6 class="pa-3">
          <v-layout column fill-height justify-center>
            <v-btn :loading="authenticatingGoogle" class="my-2" color="#d34836" @click="loginGoogle">
              {{ $t('auth.google') }}
            </v-btn>
            <v-btn :loading="authenticatingFacebook" class="my-2" color="#3b5998" @click="loginFacebook">
              {{ $t('auth.facebook') }}
            </v-btn>
            <span class="caption text-center">{{ $t('auth.createsAccount') }}</span>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </auth-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import authContainer from './tl-auth-container.vue'
import * as rules from '@/services/input-rules'
import { beforeRouteEnter as loginGuard } from './auth.guard'

export default {
  mixins: [toolbarMixin],
  components: {
    authContainer,
  },
  head() {
    return {
      title: this.$t('auth.loginTitle'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('auth.loginDescription') }],
    }
  },
  data: () => ({
    pageType: 'sign_in',
    email: '',
    pwd: '',
    pwdConfirm: '',
    showPwd: false,
    showPwdConfirm: false,
    newsletters: false,
    authenticating: false,
    authenticatingGoogle: false,
    authenticatingFacebook: false,
    valid: true,
    rules,
  }),
  computed: {
    tlToolbarTitle() {
      return this.$t('sidenav.signIn')
    },
    isSignUp() {
      return this.pageType == 'sign_up'
    },
    toggleTypeBtnText() {
      return this.isSignUp ? this.$t('auth.signIn') : this.$t('auth.signUp')
    },
    submitBtnText() {
      return this.isSignUp ? this.$t('auth.signUp') : this.$t('auth.signIn')
    },
    submitDisabled() {
      return this.isSignUp ? !this.valid : false
    },
    rulesEmail() {
      return this.isSignUp ? [this.rules.required, this.rules.email] : []
    },
    rulesPwd() {
      return this.isSignUp ? [this.rules.required, this.rules.pwdMinLength] : []
    },
    rulesPwdConfirm() {
      return this.isSignUp ? [this.rules.required, this.rules.pwdMatch(this.pwd)] : []
    },
  },
  beforeRouteEnter: loginGuard,
  methods: {
    togglePageType() {
      this.pageType = this.isSignUp ? 'sign_in' : 'sign_up'
    },
    submit(e) {
      e.preventDefault()
      this.isSignUp ? this.signup() : this.login()
    },
    loginGoogle() {
      this.authenticatingGoogle = true
      if (window.plugins && window.plugins.googleplus) {
        let onError = () => {
          this.$store.dispatch('toast/error', this.$t('generic.errorMsg'))
          this.authenticatingGoogle = false
        }
        let onSuccess = ({ idToken }) => {
          this.$store
            .dispatch('auth/oauthGoogleCallback', idToken)
            .then(() => this.$router.push({ name: 'dashboard' }))
            .finally(() => (this.authenticatingGoogle = false))
        }
        window.plugins.googleplus.login({ webClientId: process.env.VUE_APP_WEB_CLIENT_ID }, onSuccess, onError)
      } else {
        window.location.href = process.env.VUE_APP_URL_API + '/users/auth/google_oauth2'
      }
    },
    loginFacebook() {
      this.authenticatingFacebook = true
      window.location.href = process.env.VUE_APP_URL_API + '/users/auth/facebook'
    },
    login() {
      this.authenticating = true
      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.pwd,
        })
        .then(() => {
          this.$store.dispatch('toast/success', this.$t('auth.msgLoggedIn'))
          this.$router.pushSmart({ name: 'dashboard' })
        })
        .catch(() => {})
        .finally(() => {
          this.authenticating = false
        })
    },
    signup() {
      if (!this.$refs.form.validate()) return

      let userProps = {
        email: this.email,
        password: this.pwd,
        password_confirmation: this.pwdConfirm,
        newsletters: this.newsletters,
      }

      this.authenticating = true
      this.$store
        .dispatch('auth/signUp', userProps)
        .then(async () => {
          await this.$store.dispatch('auth/login', userProps)
          this.$router.pushSmart({ name: 'dashboard' })
        })
        .catch(() => {})
        .finally(() => {
          this.authenticating = false
        })
    },
  },
}
</script>

<style lang="sass" scoped>
.login
  &__header
    background-color: white
    color: black
    width: 100%
  &__header-text
    position: relative
    display: inline-block
    margin: 42px 0 65px
  &__header-sub
    position: absolute
    right: 0
    top: 120%
    color: var(--v-grey-base)
    &--center
      left: 0
  &__header-icon
    font-size: 120px
  &__form-container
    background-color: #303030
    color: var(--v-grey-base)
  &__type-divider-line
    border-color: var(--v-grey-base)
    border-style: solid
    border-width: 1px 1px 0 0
</style>
