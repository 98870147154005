<template>
  <v-container fluid class="auth px-4 align-start justify-center">
    <v-row justify="end" class="px-4 pb-4">
      <tl-locale-selector :single-line="true" />
    </v-row>

    <v-row v-if="logo" justify="center" no-gutters>
      <v-col cols="12" sm="9" md="6">
        <img class="auth__logo" src="./top-logger_logo.png" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="10" md="9" lg="7">
        <slot />
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import tlLocaleSelector from '@/components/shared/tl-locale-selector'

export default {
  components: {
    tlLocaleSelector,
  },
  props: {
    logo: { type: Boolean, default: true },
  },
}
</script>

<style lang="sass" scoped>
.auth
  min-height: 100%
  background-color: var(--v-grey-lighten4)
  &__logo
    max-width: 100%
</style>
