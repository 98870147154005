<template>
  <div class="tl-locale-selector">
    <v-menu v-if="compact" max-height="300">
      <template v-slot:activator="{ on }">
        <v-toolbar-title v-on="on">
          <v-icon>tl-language</v-icon>
        </v-toolbar-title>
      </template>
      <v-list>
        <v-list-item-group v-model="locale" mandatory>
          <v-list-item v-for="loc in locales" :key="loc.code" :value="loc.code">
            <v-list-item-title v-text="loc.name" />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <div v-else>
      <v-select
        v-model="locale"
        :items="locales"
        item-value="code"
        item-text="name"
        menu-props="auto"
        label="Language"
        hide-details
        prepend-icon="tl-language"
        :single-line="singleLine"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    compact: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('i18n', { locales: 'localeDefs' }),
    locale: {
      get() {
        return this.$i18n.locale
      },
      set(newLocale) {
        this.$store.state.user.$update({ locale: newLocale })
        this.$router.replaceSmart({ params: { locale: newLocale } })
      },
    },
  },
}
</script>

<style lang="sass">
.tl-locale-selector
  max-width: 300px
</style>
