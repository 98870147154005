<template>
  <auth-container :logo="false">
    <div class="my-5">
      <v-layout column justify-center align-end>
        <h2 class="display-2">{{ $t('auth.resetPwd') }}</h2>
        <p class="pwd-reset__subhead body-1 pt-2">
          {{ $t('auth.remember') }}
          <router-link :to="{ name: 'auth.login' }">{{ $t('auth.signIn') }}</router-link>
        </p>
      </v-layout>
    </div>
    <v-form @submit="resetPassword">
      <v-layout column>
        <v-text-field
          v-model="pwd"
          :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
          :rules="rulesPwd"
          :type="showPwd ? 'text' : 'password'"
          :label="$t('auth.password')"
          counter
          required
          prepend-icon="tl-lock"
          @click:append="showPwd = !showPwd"
        />
        <v-text-field
          v-model="pwdConfirm"
          :append-icon="showPwdConfirm ? 'tl-visibility-off' : 'tl-visibility'"
          :rules="rulesPwdConfirm"
          :type="showPwdConfirm ? 'text' : 'password'"
          :label="$t('auth.passwordConfirm')"
          required
          prepend-icon="tl-lock"
          @click:append="showPwdConfirm = !showPwdConfirm"
        />
        <v-flex align-self-end>
          <v-btn type="submit" color="primary" :loading="processing">{{ $t('auth.resetPwd') }}</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </auth-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import { beforeRouteEnter as loginGuard } from './auth.guard'
import { required, pwdMinLength, pwdMatch } from '@/services/input-rules'
import authContainer from './tl-auth-container.vue'

export default {
  mixins: [toolbarMixin],
  components: {
    authContainer,
  },
  head() {
    return {
      title: this.$t('auth.resetPwdTitle'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('auth.resetPwdDescription') }],
    }
  },
  data: () => ({
    pwd: '',
    pwdConfirm: '',
    showPwd: false,
    showPwdConfirm: false,
    rulesPwd: [required, pwdMinLength],
    processing: false,
  }),
  computed: {
    tlToolbarTitle() {
      return this.$t('auth.resetPwd')
    },
    rulesPwdConfirm() {
      return [required, pwdMatch(this.pwd)]
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.reset_password_token) return next({ name: 'auth.login' })
    loginGuard(to, from, next)
  },
  methods: {
    resetPassword(e) {
      e.preventDefault()
      let userProps = {
        password: this.pwd,
        password_confirmation: this.pwdConfirm,
        reset_password_token: this.$route.query.reset_password_token,
      }
      this.processing = true
      this.$store
        .dispatch('auth/pwdReset', userProps)
        .then(() => this.$router.push({ name: 'auth.login' }))
        .catch(() => {})
        .finally(() => (this.processing = false))
    },
  },
}
</script>

<style lang="sass" scoped>
.pwd-reset
  &__subhead
    color: var(--v-grey-base)
</style>
